import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Download from '../common/download/Download'
import TeacherComment from './TeacherComment'
import TeacherNeed from './TeacherNeed'

const TeacherPage = () => {
    // Variable
    const myRef = React.useRef(null);

    // Functions
    const onStartPress = () => {
        myRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }

    return (
        <React.Fragment>
            <Box sx={{ 
                width: '100vw', 
                height: '100vh',
                minWidth: "800px", 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-around',
                alignItems: 'center',
                overflowX: 'hidden'}}
            >
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    maxWidth: '600px',
                    ml: 3 }}
                >
                    <Typography variant='h2'>Bạn là <Typography color='primary.light' variant='h3'>giáo viên ngoại ngữ?</Typography></Typography>
                    <Typography variant='h6' sx={{mt: 2}}>
                        Nomna App được thiết kế là một công cụ cho phép giáo viên dễ dàng tạo bài luyện nghe nói
                        để học sinh tự luyện tập ở nhà và được máy tự động sửa lỗi.
                    </Typography>
                    <Button onClick={onStartPress} variant="contained" sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', maxWidth: '200px', mt: 5 }}>
                        <Typography variant="subtitle1">Bắt đầu</Typography>
                    </Button>
                </Box>

                <Box
                        component="img"
                        bgcolor='transparent'
                        sx={{
                            height: 500,
                            width: 500,
                        }}
                        alt="Logo"
                        src="image/teacher.png"
                    />
            </Box>

            <TeacherNeed />
            <TeacherComment />
            <Box ref={myRef}>
                <Download bgColor='primary.light' textColor='white' highlightColor='yellow'/>
            </Box>
        </React.Fragment>
    )
}

export default TeacherPage