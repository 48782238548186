import { Box } from '@mui/material'
import React from 'react'
import Item from '../common/item/Item';
import ItemReverse from '../common/item/ItemReverse';

const ParentNeed = () => {
    return (
        <React.Fragment>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                overflowX: 'hidden',
                width: '100vw', 
                minWidth: "800px",
                backgroundColor: 'primary.light',
                pt: 4, pb: 4}}
            >
                <Item 
                    title='Luyện tập' 
                    subtitle=' khoa học' 
                    content='Luyện tập với máy theo 1 lộ trình khoa học giúp người học cải thiện phát âm và nhanh chóng giao tiếp tự tin bằng tiếng Anh.'
                    image='image/parent_need_1.png' />

                <ItemReverse 
                    title='Luyện tập' 
                    subtitle=' tiết kiệm' 
                    content='Luyện tập với máy tiết kiệm đáng kể thời gian và chi phí'
                    image='image/parent_need_2.png' />

                <Item 
                    title='Luyện tập với giao diện' 
                    subtitle=' thân thiện' 
                    content='Giao diện Nomna AppTM được thiết kế 100% bằng tiếng Việt cho phụ huynh dễ dàng hướng dẫn cho con em mình sử dụng'
                    image='image/parent_need_3.png' />

                <ItemReverse 
                    title='Luyện tập' 
                    subtitle=' chủ động' 
                    content='Luyện tập với máy cho học sinh có sự chủ động tối đa về thời gian.'
                    image='image/parent_need_4.png' />

            </Box>
        </React.Fragment>
    )
}

export default ParentNeed;