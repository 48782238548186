import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
    palette: {
        primary: {
            main: '#763317',
        },
        secondary: {
            main: '#123458',
        },
    },
    typography: {
        fontFamily: "monospace"
    }
});