import { Box, Typography } from '@mui/material';
import React from 'react';

const Download = ({bgColor, textColor, highlightColor}) => {
    return (
        <Box sx={{
            width: '100vw', 
            height: '100vh',
            minWidth: "800px", 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'center',
            backgroundColor: bgColor,
            alignItems: 'center',
            overflowX: 'hidden'}}
        >

            <Typography variant='h4' color={textColor}>Nomna đã có mặt trên cả 2 nền tảng 
                    <Typography variant='h4' display="inline" color={highlightColor}> App Store</Typography> và 
                    <Typography variant='h4' display="inline" color={highlightColor}> Google Play</Typography>
            </Typography>

                <a href='/'>
                    <Box
                        component="img"
                        bgcolor='transparent'
                        sx={{
                            height: 100,
                            width: 300,
                            mt: 10,
                        }}
                        alt="Logo"
                        src='image/play_store.png'
                    />
                </a>
                <a href='/'>
                    <Box
                        component="img"
                        bgcolor='transparent'
                        sx={{
                            height: 100,
                            width: 300,
                            mt: 3,
                        }}
                        alt="Logo"
                        src='image/app_store.png'
                    />
                </a>
                

            
        </Box>
    )
}

export default Download;