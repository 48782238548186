import {Box, Button, Divider, Typography} from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <React.Fragment>
            {
              location.pathname !== '/term-of-use' && location.pathname !== '/privacy-policy' && (
                <>
                    <Divider />

                    <Box sx={{
                        width: '100vw',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        pt: 4,
                        pb: 4}} >

                        <Typography variant="subtitle2" component="div" color="gray">
                            Công ty TNHH Nomna Asia Pte Ltd, đăng kí kinh doanh tại Singapore UEN202101582E
                        </Typography>

                        <Typography variant="subtitle2" component="div" color="gray">
                            Liên hệ: Hello@nomna.app
                        </Typography>

                        <Box display={'flex'} flexDirection={'row'}>
                            <Button onClick={() => navigate('/privacy-policy')} sx={{textTransform: 'none', color: 'black'}}>
                                <Typography variant="subtitle2" color="gray" sx={{ml: 3, mr: 3}}>PRIVACY POLICY</Typography>
                            </Button>

                            <Button onClick={() => navigate('/term-of-use')} sx={{textTransform: 'none', color: 'black'}}>
                                <Typography variant="subtitle2" color="gray" sx={{ml: 3, mr: 3}}>TERM OF USE</Typography>
                            </Button>
                        </Box>
                    </Box>
                </>
              )
            }
        </React.Fragment>
    )
}

export default Footer