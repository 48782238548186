import React, {createContext, useEffect, useState } from 'react'
export const GlobalState = createContext()

export const DataProvider = ({children}) => {
    // Global state
    const [demoState, setDemoState] = useState("Demo State");

    // Use effect
    useEffect(() => {
        setDemoState("Demo State Use Effect");
    }, [])

    // Export state
    const state = {
        demoState: [demoState, setDemoState],
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}