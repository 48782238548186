import React from "react";
import { Box, Typography } from "@mui/material";

const ItemReverse = ({title, subtitle, content, image}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%'}}
        >
            <Box
                component="img"
                bgcolor='transparent'
                sx={{
                    height: 500,
                    width: 500,
                }}
                alt="Logo"
                src={image}
            />

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'center',
                maxWidth: '600px'}}
            >
                <Typography variant='h3' sx={{mt: 2}} color='white' display="inline">
                    {title} <Typography variant='h3' sx={{mt: 2}} color='yellow' display="inline">{subtitle}</Typography>
                </Typography>
                <Typography variant='h6' sx={{mt: 2}} color='white'>
                    {content}
                </Typography>
            </Box>
        </Box>
    );
}

export default ItemReverse;