import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Download from '../common/download/Download'
import ParentNeed from './ParentNeed'

const ParentPage = () => {
    // Variable
    const myRef = React.useRef(null);

    // Functions
    const onStartPress = () => {
        myRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }

    return (
        <React.Fragment>
            <Box sx={{ 
                width: '100vw', 
                height: '100vh',
                minWidth: "800px", 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-around',
                alignItems: 'center',
                overflowX: 'hidden'}}
            >
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    maxWidth: '600px',
                    ml: 3 }}
                >
                    <Typography variant='h2'>Bạn là <Typography color='primary.light' variant='h3'>bậc phụ huynh?</Typography></Typography>
                    <Typography variant='h6' sx={{mt: 2}}>
                        Bạn là bậc phụ huynh ở xa các thành phố lớn và muốn con mình được học thêm tiếng Anh chất lượng cao và phát âm chuẩn?
                        Nomna App là giải pháp gần nhất với trải nghiệm có giáo viên bản ngữ hướng dẫn cho mình luyện tập và sửa lỗi.
                    </Typography>
                    <Button onClick={onStartPress} variant="contained" sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', maxWidth: '200px', mt: 5 }}>
                        <Typography variant="subtitle1">Bắt đầu</Typography>
                    </Button>
                </Box>

                <Box
                        component="img"
                        bgcolor='transparent'
                        sx={{
                            height: 500,
                            width: 500,
                        }}
                        alt="Logo"
                        src="image/parent.png"
                    />
            </Box>

            <ParentNeed/>
            <Box ref={myRef}>
                <Download bgColor='white' textColor='black' highlightColor='primary.light' />
            </Box>
        </React.Fragment>
    )
}

export default ParentPage