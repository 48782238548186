import { Box } from '@mui/material'
import React from 'react'
import Item from '../common/item/Item'
import ItemReverse from '../common/item/ItemReverse'

const LearnerNeed = () => {
    return (
        <React.Fragment>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                overflowX: 'hidden',
                width: '100vw', 
                minWidth: "800px",
                backgroundColor: 'primary.light',
                pt: 4, pb: 4}}
            >
                <Item 
                    title='Luyện tập' 
                    subtitle='thoải mái' 
                    content='Luyện tập phát âm thoải mái, đi từ dễ đến khó tùy theo sự lựa chọn của bạn'
                    image='image/learner_need_1.png' />

                <ItemReverse
                    title='Luyện tập' 
                    subtitle='qua bài hát' 
                    content='Học tiếng Anh qua bài hát, qua thơ theo yêu cầu của học viên'
                    image='image/learner_need_2.png' />
                    
            </Box>

            
        </React.Fragment>
    )
}

export default LearnerNeed