import React from 'react'
import {Box, Typography} from "@mui/material";

const TermOfUse = () => {
  return (
    <React.Fragment>
      <Box sx={{padding: "5vh 40px 40px"}}>
        <Typography variant="h4" sx={{textAlign: "center"}}><strong>Thỏa thuận SỬ DỤNG NOMNA APP</strong></Typography>
        <Typography variant="h5" sx={{textAlign: "center"}}><strong>(D&Agrave;NH CHO GI&Aacute;O VI&Ecirc;N)</strong></Typography>
        <br />
        <p>Cảm ơn bạn đ&atilde; quan t&acirc;m tham gia sử dụng ứng dụng <strong>Nomna App</strong> với tư c&aacute;ch l&agrave; gi&aacute;o vi&ecirc;n/người tạo nội dung ( &ldquo;<strong>gi&aacute;o vi&ecirc;n</strong>&rdquo;).</p>
        <br />
        <p><strong>Nomna App (&ldquo;App&rdquo;) </strong>được ph&aacute;t triển bởi <strong>Nomna Education</strong> / <strong>Nomna Asia Pte Ltd</strong> (&ldquo;<strong>Nomna</strong>&rdquo;) l&agrave; một ứng dụng kĩ thuật số cho ph&eacute;p người sử dụng với vai tr&ograve; gi&aacute;o vi&ecirc;n tạo nội dung v&agrave; ph&acirc;n phối nội dung tới những người d&ugrave;ng kh&aacute;c. Khi đăng k&iacute; tham gia Nomna App với vai tr&ograve; <strong>gi&aacute;o vi&ecirc;n</strong>, bạn đồng &yacute; chấp thuận c&aacute;c điều khoản sau:</p>
        <br />
        <ul>
          <li><strong>Tham gia tự nguyện:</strong></li><br /> Kh&ocirc;ng c&oacute; r&agrave;ng buộc quyền lợi hay tr&aacute;ch nhiệm n&agrave;o nảy sinh qua việc bạn đăng k&iacute; v&agrave; sử dụng <strong>Nomna App</strong> một c&aacute;ch tự nguyện, ngo&agrave;i những cam kết được n&ecirc;u trong <strong>Thỏa thuận</strong> n&agrave;y.
          <br /><br />
          <li><strong>Tạo nội dung:</strong></li><br /> Gi&aacute;o vi&ecirc;n sở hữu bản quyền cho những s&aacute;ng tạo nội dung do ch&iacute;nh m&igrave;nh tạo ra (chiểu theo những ti&ecirc;u ch&iacute; của Luật Bản Quyền). Gi&aacute;o vi&ecirc;n cam kết chịu ho&agrave;n to&agrave;n tr&aacute;ch nhiệm về nội dung m&igrave;nh đưa l&ecirc;n <strong>App</strong>. Cụ thể, gi&aacute;o vi&ecirc;n cam kết: <br /> (a) kh&ocirc;ng đưa l&ecirc;n <strong>App</strong> những nội dung kh&ocirc;ng ph&ugrave; hợp với gi&aacute;o dục; <br /> (b) kh&ocirc;ng đưa l&ecirc;n <strong>App</strong> những nội dung c&oacute; <strong>bản quyền</strong> m&agrave; kh&ocirc;ng được sự cho ph&eacute;p của t&aacute;c giả<br /> (L&agrave;m r&otilde;: nh&uacute;ng Youtube video v&agrave;o App kh&ocirc;ng phải l&agrave; sự sao ch&eacute;p nội dung m&agrave; l&agrave; thao t&aacute;c &ldquo;hướng li&ecirc;n kết&rdquo; n&ecirc;n kh&ocirc;ng vi phạm bản quyền. T&aacute;i tạo lại một phần nội dung video, b&oacute;c t&aacute;ch, sắp xếp lại, chuyển ngữ hoặc ph&acirc;n t&iacute;ch theo g&oacute;c nh&igrave;n của gi&aacute;o vi&ecirc;n l&agrave; những thao t&aacute;c s&aacute;ng tạo n&ecirc;n cũng kh&ocirc;ng vi phạm bản quyền của Youtube video)
          <br /><br />
          <li><strong>Quản l&yacute; v&agrave; kinh doanh nội dung:</strong></li><br />Gi&aacute;o vi&ecirc;n cho ph&eacute;p <strong>Nomna</strong> to&agrave;n quyền sửa đổi, bổ sung, sao ch&eacute;p, thay thế, nhượng quyền lại, xuất bản hoặc gỡ bỏ bất cứ nội dung n&agrave;o trong <strong>App</strong>, c&oacute; hoặc kh&ocirc;ng c&oacute; b&aacute;o trước. Khi nội dung được đưa v&agrave;o kinh doanh (thời điểm v&agrave; h&igrave;nh thức kinh doanh do <strong>Nomna</strong> quyết định), <strong>Nomna</strong> v&agrave; <strong>gi&aacute;o vi&ecirc;n</strong> sẽ tạo thỏa thuận ri&ecirc;ng về chia sẻ doanh thu.
          <br /><br />
          <li><strong>Bảo to&agrave;n nội dung:</strong></li><br /> Trong qu&aacute; tr&igrave;nh sử dụng, <strong>Nomna App</strong> c&oacute; thể được n&acirc;ng cấp phi&ecirc;n bản mới. Gi&aacute;o vi&ecirc;n chấp nhận rằng <strong>Nomna</strong> kh&ocirc;ng chịu bất cứ tr&aacute;ch nhiệm n&agrave;o về việc bảo to&agrave;n v&agrave; t&aacute;i tạo nội dung cũ sau mỗi lượt n&acirc;ng cấp hoặc khi gặp sự cố kĩ thuật. V&igrave; vậy, <strong>Nomna</strong> khuyến c&aacute;o gi&aacute;o vi&ecirc;n lưu trữ &iacute;t nhất 1 phi&ecirc;n bản nội dung ở b&ecirc;n ngo&agrave;i <strong>App</strong>.
          <br /><br />
          <li><strong>T&ocirc;n trọng sở hữu tr&iacute; tuệ (SHTT):</strong></li> <br /><strong>Nomna App</strong> đ&atilde; v&agrave; đang được đăng k&iacute; bảo vệ sở hữu tr&iacute; tuệ ở nhiều quốc gia. <strong>Gi&aacute;o vi&ecirc;n</strong> cam kết kh&ocirc;ng sao ch&eacute;p, chia sẻ, h&eacute; lộ m&atilde; nguồn, kh&ocirc;ng t&aacute;i tạo lại <strong>App</strong> (reverse engineering), kh&ocirc;ng lưu giữ, ph&aacute;t t&aacute;n c&aacute;c t&agrave;i liệu, h&igrave;nh ảnh, video li&ecirc;n quan đến qu&aacute; tr&igrave;nh đ&agrave;o tạo sử dụng <strong>Nomna App</strong> m&agrave; kh&ocirc;ng được sự cho ph&eacute;p của <strong>Nomna</strong>.
          <br /><br />
          <li><strong>Bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n:</strong></li><br /><strong>Nomna</strong> cam kết bảo mật th&ocirc;ng tin c&aacute; nh&acirc;n của người d&ugrave;ng theo những chuẩn mực an to&agrave;n th&ocirc;ng tin phổ biến, kh&ocirc;ng sử dụng th&ocirc;ng tin c&aacute; nh&acirc;n cho mục đ&iacute;ch thương mại b&ecirc;n ngo&agrave;i m&agrave; kh&ocirc;ng c&oacute; sự đồng &yacute; của người d&ugrave;ng.
          <br /><br />
          <li><strong>Thời hạn hiệu lực:</strong></li><br /><strong>Thỏa thuận</strong> n&agrave;y c&oacute; hiệu lực kể từ ng&agrave;y t&agrave;i kho&agrave;n gi&aacute;o vi&ecirc;n được k&iacute;ch hoạt th&agrave;nh c&ocirc;ng tr&ecirc;n Nomna App. Thỏa thuận n&agrave;y được chấm dứt khi 1 trong 2 b&ecirc;n th&ocirc;ng b&aacute;o cho b&ecirc;n kia bằng văn bản (bao gồm email) về việc chấm dứt Thỏa thuận. 2 b&ecirc;n hiểu r&otilde; rằng, bất chấp việc Thỏa thuận n&agrave;y được chấm dứt, c&aacute;c điều khoản về <strong>T&ocirc;n trọng SHTT</strong> sẽ vẫn tiếp tục c&oacute; hiệu lực.
          <br /><br />
          <li><strong>C&aacute;c sửa đổi kh&aacute;c:</strong></li><br /> <strong>Nomna</strong> c&oacute; quyền sửa đổi, x&oacute;a hoặc bổ sung điều khoản v&agrave;o <strong>Thỏa thuận</strong> n&agrave;y tại bất cứ thời điểm n&agrave;o. C&aacute;c thay đổi sẽ c&oacute; hiệu lực <br /> (i) 30 ng&agrave;y sau khi <strong>Nomna</strong> cung cấp th&ocirc;ng b&aacute;o về c&aacute;c thay đổi; hoặc <br /> (ii) khi người d&ugrave;ng đồng &yacute; với c&aacute;c thay đổi, <br /> t&ugrave;y theo điều kiện n&agrave;o đến trước.
          <br /><br />
          <li><strong>Tranh tụng v&agrave; khiếu nại</strong>:</li> <br /> Mọi tranh tụng v&agrave; khiếu nại li&ecirc;n quan đến <strong>Thỏa thuận</strong> n&agrave;y, nếu kh&ocirc;ng tự h&ograve;a giải được giữa 2 b&ecirc;n, sẽ được đưa ra ph&acirc;n định bởi trọng t&agrave;i kinh tế tại th&agrave;nh phố Hồ Ch&iacute; Minh, Việt Nam, chiểu theo luật Việt Nam.
        </ul>
        <p><br /> Mọi thắc mắc xin gửi về: hello@nomna.app</p>
      </Box>
    </React.Fragment>
  )
}

export default TermOfUse