import React from 'react'
import { Box } from '@mui/system';
import Carousel from 'react-material-ui-carousel';
import Comment from '../common/comment/Comment';

const TeacherComment = () => {
    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    width: '500px',
                    pb: 10,
                    pt: 10}}>
                
                    <Carousel
                        animation="slide"
                        indicators={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsProps={{
                            style: {
                                backgroundColor: "#123458",
                            },
                        }}
                        autoPlay={true}
                        >
                        
                        <Comment
                            name="TC"
                            comment="Trước khi ứng dụng Nomna App, tôi thường bỏ ra 5 tiếng một tuần để soạn và chấm bài tập về nhà cho 200 học sinh ở 4 lớp. 
                            Nay tôi chỉ mất 1 tiếng" />

                        <Comment
                            name="AD"
                            comment="Chức năng Nomna AI tự động phản hồi thật tuyệt vời! Các học sinh của tôi thích mê!" />

                        <Comment
                            name="VD"
                            comment="80% học sinh của tôi có tiến bộ rõ rệt về phát âm và tự tin giao tiếp sau 1 học kì áp dụng Nomna App" />

                    </Carousel>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default TeacherComment;