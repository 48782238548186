import { ThemeProvider } from '@mui/system';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Footer from './common/footer/Footer';
import Header from './common/header/Header';
import { DataProvider } from './GlobalState';
import MainRouter from './router/router';
import { themeOptions } from './Theme';
import { useLocation } from 'react-router-dom';

function App() {
  return (
    <ThemeProvider theme={themeOptions}>
      <DataProvider>
        <BrowserRouter>
          <Header />
          <MainRouter />
          <Footer/>
        </BrowserRouter>
      </DataProvider>
    </ThemeProvider>
  );
}

export default App;
