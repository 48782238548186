import { Box } from '@mui/material'
import React from 'react'
import Item from '../common/item/Item'
import ItemReverse from '../common/item/ItemReverse'

const TeacherNeed = () => {
    return (
        <React.Fragment>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                overflowX: 'hidden',
                width: '100vw', 
                minWidth: "800px",
                backgroundColor: 'primary.light',
                pt: 4, pb: 4}}
            >

                <Item 
                    title='Giảng dạy mọi' 
                    subtitle=' ngôn ngữ' 
                    content='Nomna App được thiết kế phù hợp với việc giảng dạy cho mọi cặp ngôn ngữ.'
                    image='image/teacher_need_1.png' />

                <ItemReverse 
                    title='Tiết kiệm' 
                    subtitle=' thời gian' 
                    content='Giáo viên chỉ cần ít phút là có thể tạo bài giảng và tải lên app cho mọi học sinh của mình truy cập. 
                        Tiết kiệm tới 80% thời gian giáo viên phải bỏ ra để quản lý bài tập về nhà của học sinh'
                    image='image/parent_need_2.png' />

                <Item 
                    title='Bài luyện tập được tạo' 
                    subtitle=' tự động' 
                    content='Các bài luyện được tự động tạo từ bài giảng mà không mất công giáo viên soạn thêm'
                    image='image/teacher_need_3.png' />

                <ItemReverse 
                    title='Cấu trúc bài' 
                    subtitle=' đa dạng' 
                    content='Các bài luyện đa dạng giúp học sinh học kĩ và nhớ lâu từ vựng cũng như cấu trúc ngữ pháp'
                    image='image/teacher_need_4.png' />

                <Item 
                    title='Phản hồi nhanh chóng bằng' 
                    subtitle=' AI' 
                    content='Nomna AI  phản hồi tức thì cho mọi thao tác của học sinh'
                    image='image/teacher_need_5.png' />

            </Box>
        </React.Fragment>
    )
}

export default TeacherNeed