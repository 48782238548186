import React from "react";
import { Avatar, Typography, Box } from '@mui/material';

const Comment = ({name, comment}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'}}>

            <Box sx={{
                width: '300px',
                height: '300px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <Avatar sx={{ height: 75, width: 75, bgcolor: "red"}}>{name}</Avatar>
                <Typography variant="subtitle1" sx={{textAlign: 'center', pt: 3}}>{comment}</Typography>
            </Box>
        </Box>
    )
}

export default Comment;