import { Box, Button, Divider, Typography } from '@mui/material'
import {useLocation, useNavigate} from 'react-router-dom';
import React from 'react'

const Header = () => {
    const [pathName, setPathName] = React.useState("/learner");

    // Variables
    const navigate = useNavigate();
    const location = useLocation();
    
    // Functions
    const handleNavigate = (path) => {
        if (path === "/") {
            setPathName("/learner");
        } else {
            setPathName(path);
        }

        navigate(path);
    }

    // Use effect
    React.useEffect(() => {
        if (window.location.pathname === "/") {
            setPathName("/learner");
        } else {
            setPathName(window.location.pathname);
        }
    }, []);

    return (
        <React.Fragment>
            {
              location.pathname !== '/term-of-use' && location.pathname !== '/privacy-policy' && (
                <>
                    <Box sx={{
                        width: '100vw',
                        minWidth: "800px",
                        height: '75px',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        position: 'fixed',
                        backgroundColor: 'white',
                        zIndex: '1000',
                    }} >
                        <Box onClick={() => handleNavigate('/')} sx={{ display: 'flex', flexDirection: 'row', width: 'fit-content', alignItems: 'center', pl: 3 }}>
                            <Box
                              component="img"
                              sx={{
                                  height: 75,
                                  width: 75,
                              }}
                              alt="Logo"
                              src="image/logo.png"
                            />

                            <Typography variant="h6" component="div" color="primary" sx={{fontWeight: 'bold'}}>Nomna App</Typography>
                        </Box>

                        <Box sx={{ display: 'flex', flexDirection: 'row', width: 'fit-content'}}>
                            <Button onClick={() => handleNavigate('/learner')} sx={{textTransform: 'none', color: 'black'}}>
                                {
                                    pathName === "/learner"
                                      ? <Typography variant="h6" sx={{ml: 3, mr: 3, fontWeight: 'bold'}} color='primary.light'>Học viên</Typography>
                                      : <Typography variant="h6" sx={{ml: 3, mr: 3}}>Học viên</Typography>
                                }
                            </Button>

                            <Button onClick={() => handleNavigate('/teacher')} sx={{textTransform: 'none', color: 'black'}}>
                                {
                                    pathName === "/teacher"
                                      ? <Typography variant="h6" sx={{ml: 3, mr: 3, fontWeight: 'bold'}} color='primary.light'>Giáo viên</Typography>
                                      : <Typography variant="h6" sx={{ml: 3, mr: 3}}>Giáo viên</Typography>
                                }
                            </Button>

                            <Button onClick={() => handleNavigate('/parent')} sx={{textTransform: 'none', color: 'black'}}>
                                {
                                    pathName === "/parent"
                                      ? <Typography variant="h6" sx={{ml: 3, mr: 3, fontWeight: 'bold'}} color='primary.light'>Phụ huynh</Typography>
                                      : <Typography variant="h6" sx={{ml: 3, mr: 3}}>Phụ huynh</Typography>
                                }
                            </Button>
                        </Box>

                        <Box></Box>
                    </Box>

                    <Divider/>
                </>
              )
            }
        </React.Fragment>
    )
}

export default Header