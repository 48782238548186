import React from 'react';
import {Routes, Route} from 'react-router-dom';
import ErrPage from '../common/404/404';
import LearnerPage from '../for_learners/LearnerPage';
import ParentPage from '../for_parents/ParentPage';
import TeacherPage from '../for_teachers/TeacherPage';
import TermOfUse from "../term_of_use/TermOfUse";
import PrivacyPolicy from "../privacy_policy/PrivacyPolicy";

//Import pages

/*
	react-router-dom v6
	docs: https://reactrouter.com/docs/en/v6/upgrading/v5
*/

export default function MainRouter({ref}) {
    return (
        <Routes>
            <Route path='/' element={<LearnerPage ref={ref} />} />
            <Route path='/learner' element={<LearnerPage ref={ref} />} />
            <Route path='/teacher' element={<TeacherPage ref={ref} />} />
            <Route path='/parent' element={<ParentPage ref={ref} />} />
            <Route path='/term-of-use' element={<TermOfUse />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />

            <Route path="*" element={<ErrPage />} />
        </Routes>
    )

}