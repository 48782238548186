import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Download from '../common/download/Download'
import LearnerComment from './LearnerComment'
import LearnerNeed from './LearnerNeed'

const LearnerPage = () => {
    // Variable
    const myRef = React.useRef(null);

    // Functions
    const onStartPress = () => {
        myRef.current.scrollIntoView({behavior: "smooth", block: "start"});
    }

    return (
        <React.Fragment>
            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-around',
                alignItems: 'center',
                overflowX: 'hidden',
                height: '100vh', 
                width: '100vw', 
                minWidth: "800px"}}
                >

                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '600px',
                    pl: 3 }}
                >
                    <Typography variant='h2'>Bạn là <Typography color='primary.light' variant='h3'>học sinh, sinh viên?</Typography></Typography>
                    <Typography variant='h6' sx={{mt: 2}}>
                        Với Nomna App, bạn hoàn toàn yên tâm được học phát âm một cách bài bản, 
                        hệ thống với giọng người bản ngữ theo phương pháp độc quyền của Nomna đã đăng kí sáng chế ở Mỹ.
                    </Typography>
                    <Button onClick={onStartPress} variant="contained" sx={{color: 'white', textTransform: 'none', fontWeight: 'bold', maxWidth: '200px', mt: 5 }}>
                        <Typography variant="subtitle1">Bắt đầu</Typography>
                    </Button>
                </Box>

                <Box
                        component="img"
                        bgcolor='transparent'
                        sx={{
                            height: 500,
                            width: 500,
                        }}
                        alt="Logo"
                        src="image/learner.png"
                    />
            </Box>

            <LearnerNeed />
            <LearnerComment />
            <Box ref={myRef}>
                <Download bgColor='primary.light' textColor='white' highlightColor='yellow'/>
            </Box>
        </React.Fragment>
    )
}

export default LearnerPage