import React from 'react'
import { Box } from '@mui/system';
import Carousel from 'react-material-ui-carousel';
import Comment from '../common/comment/Comment';

const LearnerComment = () => {
    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    width: '500px',
                    pb: 10,
                    pt: 10}}>
                
                    <Carousel
                        animation="slide"
                        indicators={false}
                        navButtonsAlwaysVisible={true}
                        navButtonsProps={{
                            style: {
                                backgroundColor: "#123458",
                            },
                        }}
                        autoPlay={true}
                        >
                        
                        <Comment
                            name="JD"
                            comment="Bài học Nomna bám sát với chương trình tiếng Anh phổ thông, 
                                giúp em nắm vững bài trên lớp, tự tin giao tiếp. Điểm tiếng Anh của em tiến lên top 5 của lớp sau 1 học kì. 
                                Ai cũng khen em phát âm tiếng Anh tiến bộ hẳn" />

                        <Comment
                            name="A"
                            comment="Nomna App giúp em tự sửa lỗi rất nhiều. 
                                Nhờ thế em ít mắc lỗi trên lớp và điểm tiếng Anh càng ngày càng tiến bộ. 
                                Giờ em thích học tiếng Anh chứ không sợ như hồi trước" />

                    </Carousel>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default LearnerComment;