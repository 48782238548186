import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'
import { Link } from 'react-router-dom';


const ErrPage = () => {
    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '100vh' }}
            >
                <Box sx={{ width: '100vw'}}>
                    <Box sx={{ mx: 'auto', width: 'fit-content'}}>
                        <Typography variant="h1" component="div" gutterBottom>404</Typography>
                    </Box>

                    <Box sx={{ mx: 'auto', width: 'fit-content'}}>
                        <Typography variant="h2" component="div" gutterBottom>Page not found</Typography>
                    </Box>
                    
                    <Box sx={{ mx: 'auto', width: 'fit-content'}}>
                        <Typography variant="body1" gutterBottom>
                            The page you are looking for does not exist.
                        </Typography>
                    </Box>

                    <Box sx={{ mx: 'auto', width: 'fit-content'}}>
                        <Typography variant="body1" gutterBottom>
                            You can go back to the <Link to="/">homepage</Link>
                        </Typography>
                    </Box>
                </Box>

            </Grid>
        </React.Fragment>
    )
}


export default ErrPage